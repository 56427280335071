import React from 'react'
import PropTypes from 'prop-types'

const HeadlineComponent = ({
    tagHeadline, text, className, styleHeadline,
}) => {
    const modeHeadline = (text) => {
        if (!text) {
            return null
        }

        if (tagHeadline === 'h1') {
            return (
                <h1 className={className} style={styleHeadline}>{text}</h1>
            )
        }
        if (tagHeadline === 'h2') {
            return (
                <h2 className={className} style={styleHeadline}>{text}</h2>
            )
        }
        if (tagHeadline === 'h3') {
            return <h3 className={className} style={styleHeadline}>{text}</h3>
        }
        if (tagHeadline === 'h4') {
            return <h4 className={className} style={styleHeadline}>{text}</h4>
        }
        if (tagHeadline === 'h5') {
            return <h5 className={className} style={styleHeadline}>{text}</h5>
        }
        if (tagHeadline === 'h6') {
            return <h6 className={className} style={styleHeadline}>{text}</h6>
        }

        return (
            <h1 className={className} style={styleHeadline}>{text}</h1>
        )
    }

    return (
        <>
            {modeHeadline(text, className, styleHeadline)}
        </>
    )
}

HeadlineComponent.propTypes = {
    text: PropTypes.string.isRequired,
    tagHeadline: PropTypes.string,
    styleHeadline: PropTypes.object,
    className: PropTypes.string,
}

export default HeadlineComponent
