import React from 'react'
import PropTypes from 'prop-types'
import Image from '../../atoms/image'
import FrontasticImage from '../../atoms/frontasticImage'
import Reference from '../../../components/reference'

const Brand = (props) => {
    const {
        media, alt, reference, rel,
    } = props

    return (
        <div className={'brand-wrapper'}>
            <div className={'brand-image'}>
                <Reference
                    reference={reference}
                    rel={rel}
                >
                    <FrontasticImage
                        media={media.media}
                        title={alt}
                    />
                </Reference>
            </div>
        </div>
    )
}

Brand.propTypes = {
    media: PropTypes.object,
    alt: PropTypes.string,
    reference: PropTypes.object,
    rel: PropTypes.string,
}

export default Brand
