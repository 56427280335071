import React from 'react'
import PropTypes from 'prop-types'
import ComponentInjector from '@frontastic/catwalk/src/js/app/injector'
import { SwiperSlide } from 'swiper/react'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import classnames from 'classnames'
import Brand from '../../../molecules/Brand'
import HeadlineComponent from '../../../atoms/headline'
import Slider from '../../../atoms/slider'
import FullPageWidthWrapper from '../../../molecules/Layout/FullPageWidthWrapper'

const BrandSlider = ({
    brand, headline, tagHeadline, headlinePos, isDarkMode, swiperColor,
}) => {
    if (!brand) {
        return null
    }
    const maxItemsPerGroup = 8
    const maxItemsPerGroupMobile = 4
    const slidePerGroup = brand.length < maxItemsPerGroup ? brand.length : maxItemsPerGroup
    const slidePerGroupMobile = brand.length < maxItemsPerGroup ? Math.ceil(parseFloat(brand.length / 2)) : maxItemsPerGroupMobile
    const isMobile = useDeviceType() === 'mobile'
    const brandList = () => brand.map((item, index) => {
        const { alt, reference, rel } = item

        if (isMobile) {
            return (
                <Brand
                    reference={reference}
                    media={item.image}
                    alt={alt}
                    rel={rel}
                    key={alt}
                />
            )
        }

        return (
            <SwiperSlide key={alt}>
                <Brand
                    reference={reference}
                    media={item.image}
                    alt={alt}
                    rel={rel}
                />
            </SwiperSlide>
        )
    })

    const renderBrandByDevice = () => {
        if (isMobile) {
            return (
                <div className={'brand-slider-mobile'}>
                    {brandList()}
                </div>
            )
        }
        return (
            <Slider
                swiperColor={swiperColor}
                spaceBetween={24}
                slidesPerView={6}
                slidesPerGroup={3}
                breakpoints={{
                    768: {
                        slidesPerView: slidePerGroupMobile,
                        slidesPerGroup: slidePerGroupMobile,
                    },
                    1024: {
                        slidesPerView: slidePerGroup,
                        slidesPerGroup: slidePerGroupMobile,
                    },
                }}
            >
                {brandList()}
            </Slider>
        )
    }

    return (
        <FullPageWidthWrapper customClasses={classnames('brands-slider-component relative', {
            'dark-mode bg-blacksport-100 text-white py-comp': isDarkMode,
        })}
        >
            <div className={'o-wrapper'}>
                <div className={'o-grid o-region'}>
                    <div className={'o-cell o-cell--12'}>
                        <div className={'brand-slider-wrapper'}>
                            <HeadlineComponent
                                className={`${headlinePos}`}
                                text={headline}
                                tagHeadline={tagHeadline}
                            />
                            {renderBrandByDevice()}
                        </div>
                    </div>
                </div>
            </div>
        </FullPageWidthWrapper>
    )
}

BrandSlider.propTypes = {
    headline: PropTypes.string,
    tagHeadline: PropTypes.string,
    headlinePos: PropTypes.string,
    isDarkMode: PropTypes.bool,
    swiperColor: PropTypes.string,
    brand: PropTypes.arrayOf(PropTypes.shape({
        image: PropTypes.object,
        alt: PropTypes.string,
    })).isRequired,
}

export default ComponentInjector.return('BrandSlider', BrandSlider)
