import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Link from '@frontastic/catwalk/src/js/app/link'
import { isReferenceAbsoluteHttpOrMailtoLink } from '@frontastic/catwalk/src/js/app/referenceHelper'

/**
 * Provides a Link to a Reference (which means either a link to an external site or a node).
 */
class Reference extends Component {
    render() {
        const { reference, children } = this.props

        if (!reference) {
            // eslint-disable-next-line no-console
            console.warn('The reference given is not a proper reference, so we just returning null here.', reference)
            return null
        }

        if (!reference.type) {
            return <>{children}</>
        }

        if (isReferenceAbsoluteHttpOrMailtoLink(reference)) {
            return (
                <a href={reference.link} {...this.targetProps()} {...this.additionalProps()}>
                    {children}
                </a>
            )
        }

        return (
            <Link {...this.linkProps()} {...this.targetProps()} {...this.additionalProps()}>
                {children}
            </Link>
        )
    }

    additionalProps = () => ({
        style: this.props.style,
        onMouseEnter: this.props.onMouseEnter,
        onClick: this.props.onClick,
        className: this.props.className,
        rel: this.props.rel,
    })

    targetProps = () => {
        if (this.props.target === '_blank' || this.props.reference.openInNewWindow) {
            return {
                target: '_blank',
                rel: 'noopener',
            }
        }

        return {}
    }

    linkProps = () => {
        switch (this.props.reference.type) {
            case 'link':
                return {
                    path: this.props.reference.link,
                }

            case 'page-folder':
                return {
                    path: this.props.reference.pageFolder._url,
                }

            default:
                return {}
        }
    }
}

export const ReferencePropType = {
    reference: PropTypes.object.isRequired,
    children: PropTypes.any,
    className: PropTypes.string,
    style: PropTypes.object,
    target: PropTypes.string,
    onMouseEnter: PropTypes.func,
    onClick: PropTypes.func,
    rel: PropTypes.string,
}

Reference.propTypes = ReferencePropType

Reference.defaultProps = {}

export default Reference
